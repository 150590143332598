<template>
  <Dialog :name="name" width="600px">
    <template #title>
      <div>{{ titleText }}</div>
    </template>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="editForm"
      @submit.native.prevent
    >
      <div class="editForm__pickers">
        <div class="editForm__pickers-block">
          <div>{{ isMobile ? 'Дата начала' : 'С' }}</div>

          <el-form-item prop="date_from" class="datepicker">
            <el-date-picker
              v-model="ruleForm.date_from"
              :clearable="false"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              placeholder=""
              :picker-options="pickerOptions"
              size="medium"
              class="datepicker__input"
              @change="handleCheckDate"
              @focus="clearValidate('date_from')"
            />
          </el-form-item>

          <div v-if="isMobile" class="editForm__now">
            <el-button
              class="editForm__now-button"
              type="text"
              @click="setDateNow"
            >
              Прямо сейчас
            </el-button>
          </div>

          <div>{{ isMobile ? 'Время начала' : '-' }}</div>

          <el-form-item class="timepicker" prop="time_from">
            <el-time-select
              v-model="ruleForm.time_from"
              :clearable="false"
              size="medium"
              :picker-options="pikerOptionsTimeFrom"
              class="timepicker__input"
              @change="handleSetTime"
              @focus="clearValidate('time_from')"
            />
          </el-form-item>
        </div>

        <div class="editForm__pickers-block">
          <div>{{ isMobile ? 'Дата окончания' : 'по' }}</div>

          <el-form-item class="datepicker" prop="date_to">
            <el-date-picker
              v-model="ruleForm.date_to"
              :clearable="false"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              placeholder=""
              :picker-options="pickerOptionsDateTo"
              size="medium"
              :disabled="isDisableDateTo"
              class="datepicker__input"
              @change="handleCheckDateTo"
              @focus="clearValidate('date_to')"
            />
          </el-form-item>

          <div>{{ isMobile ? 'Время окончания' : '-' }}</div>

          <el-form-item class="timepicker" prop="time_to">
            <el-time-select
              v-model="ruleForm.time_to"
              :clearable="false"
              size="medium"
              :disabled="isDisableTimeTo"
              :picker-options="pikerOptionsTimeTo"
              class="timepicker__input"
              @change="handleSetTime"
              @focus="clearValidate('time_to')"
            />
          </el-form-item>
        </div>
      </div>
      <div v-if="!isMobile" class="editForm__now">
        <el-button class="editForm__now-button" type="text" @click="setDateNow">
          Прямо сейчас
        </el-button>
      </div>
      <el-form-item
        label-width="200px"
        label="Культура"
        size="medium"
        class="editForm__select"
      >
        <el-select
          v-model="ruleForm.culture_id"
          placeholder="Выберите культуру"
          :class="{ 'w-100p': isMobile }"
        >
          <el-option
            v-for="option in cultureList"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="200px"
        class="editForm__select"
        label="Поставщик"
        size="medium"
      >
        <el-select
          v-model="ruleForm.supplier_id"
          placeholder="Выберите поставщика"
          :class="{ 'w-100p': isMobile }"
        >
          <el-option
            v-for="option in suppliersList"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="200px"
        class="editForm__input"
        label="Количество авто в буфере"
        prop="count"
        :rules="rules.count"
      >
        <el-input-number
          v-model="ruleForm.count"
          controls-position="right"
          :min="1"
          size="medium"
          :class="{ 'w-100p': isMobile }"
        />
      </el-form-item>

      <el-form-item
        label-width="200px"
        label="Настроить тип авто"
        :rules="rules.truck_type_codes"
        size="small"
        prop="truck_type_codes"
      >
        <el-checkbox-group
          v-model="ruleForm.truck_type_codes"
          :class="['truck-types', { 'w-100p': isMobile }]"
        >
          <el-checkbox
            v-for="type in autoTypeSupervisor"
            :key="type.id"
            :label="type.val"
          >
            {{ type.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        label-width="200px"
        class="editForm__select"
        label="Линия"
        prop="unload_line_id"
        :rules="rules.unload_line_id"
        size="medium"
      >
        <el-select
          v-model="ruleForm.unload_line_id"
          placeholder="Укажите линию"
          :class="{ 'w-100p': isMobile }"
          @focus="clearValidate('unload_line_id')"
        >
          <el-option
            v-for="option in supervisorLinesOptions"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>

      <div class="editForm__submit">
        <iq-button class="editForm__submit-button" @onClick="handleSubmitForm">
          {{ buttonText }}
        </iq-button>
      </div>
    </el-form>
  </Dialog>
</template>

<script>
import { DATE_FORMAT, GET_TIME, MILLISECOND_IN_DAY } from '@/constants/date'
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import {
  GET_IS_MOBILE,
  GET_SUPERVISOR_QUOTAS_LINES,
  POST_SUPERVISOR_QUOTA,
  PUT_SUPERVISOR_QUOTA,
} from '@/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import { SUPERVISOR_EDIT_QUOTA } from '@/constants/dialogs'
import {
  addHourTz,
  addIntervalDateTz,
  addIntervalTimeTz,
  diffSupervisorDates,
  diffTime,
  getFormattedTzNow,
  getLocalUnixFromTzBriefDate,
  getQuotaTzDate,
  getTime,
  getTzTime,
  nowTz,
  pickerOptions,
  timeConvert,
} from '@/core'
import { autoTypeSupervisor } from '@/constants/auto-type'
import { mapActions, mapGetters } from 'vuex'
import { ruleAddQuota } from './data'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/views/ui/components/buttons/IqButton.vue'
export default {
  name: 'EditQuotaModal',
  components: { IqButton, Dialog },
  data() {
    return {
      name: SUPERVISOR_EDIT_QUOTA,
      autoTypeSupervisor,
      ruleForm: {
        id: undefined,
        culture_id: 'all',
        supplier_id: undefined,
        count: 1,
        date_from: null,
        date_to: null,
        time_from: '',
        time_to: '',
        truck_type_codes: [],
        itemQuotaId: null,
        unload_line_id: null,
      },
      rules: ruleAddQuota,
      pickerOptions,
      // длительность правила - не более чем до конца следующего дня с даты начала
      pickerOptionsDateTo: {
        disabledDate: time =>
          getLocalUnixFromTzBriefDate(this.ruleForm?.date_from) -
            time.getTime() >=
            MILLISECOND_IN_DAY ||
          time.getTime() -
            getLocalUnixFromTzBriefDate(this.ruleForm?.date_from) >=
            MILLISECOND_IN_DAY * 2,
      },
    }
  },
  computed: {
    ...mapGetters({
      culturesFromState: GET_CULTURE_FROM_STATE,
      suppliersFromState: GET_SUPPLIERS_ALL,
      linesFromState: GET_SUPERVISOR_QUOTAS_LINES,
      isMobile: GET_IS_MOBILE,
    }),
    itemQuota() {
      return this.getDialog(this.name)?.data || {}
    },
    titleText() {
      return this.itemQuota.isEdit
        ? 'Редактирование правила вызова'
        : 'Добавление правила вызова'
    },
    buttonText() {
      return this.itemQuota.isEdit ? 'Обновить и закрыть' : 'Добавить и закрыть'
    },
    pikerOptionsTimeFrom() {
      return {
        start: '00:00',
        step: '00:15',
        end: '23:45',
        minTime: this.getMinTimeFrom,
      }
    },
    pikerOptionsTimeTo() {
      return {
        start: '00:00',
        step: '00:15',
        end: '23:45',
        minTime: this.getMinTimeTo,
      }
    },
    isDisableDateTo() {
      return !this.ruleForm.date_from
    },
    isDisableTimeTo() {
      return !this.ruleForm.time_from || !this.ruleForm.date_to
    },
    getMinTimeFrom() {
      if (
        getTzTime(this.ruleForm.date_from, DATE_FORMAT) ===
        getTzTime(nowTz(), DATE_FORMAT)
      ) {
        return getTzTime(nowTz(), GET_TIME)
      }

      return ''
    },
    getMinTimeTo() {
      if (
        this.ruleForm.time_from &&
        getTzTime(this.ruleForm.date_from, DATE_FORMAT) ===
          getTzTime(this.ruleForm.date_to, DATE_FORMAT)
      ) {
        let hours = Number(this.ruleForm.time_from.slice(0, 2))
        let minutes =
          Number(this.ruleForm.time_from.slice(3, 5)) + hours * 60 + 45

        return this.timeConvert(minutes)
      }

      return ''
    },
    allTruckTypesOptions() {
      return autoTypeSupervisor.map(item => item.val)
    },
    supervisorLinesOptions() {
      return this.linesFromState
        .slice()
        .reverse()
        .map((item, index) => ({
          id: item.id,
          name: `${index + 1} линия`,
        }))
    },
    cultureList() {
      let prepare = this.culturesFromState.map(item => ({
        id: item.id,
        name: `${item.name}  ${
          item.harvest_year ? item.harvest_year + ' года' : ''
        } `,
      }))

      return [
        {
          id: 'all',
          name: 'Любая культура',
        },
        ...prepare,
      ]
    },
    suppliersList() {
      return [{ id: null, name: 'Без поставщика' }, ...this.suppliersFromState]
    },
  },

  watch: {
    itemQuota: {
      immediate: true,
      handler(newVal) {
        this.setFormFields(
          newVal.culture_id,
          getTime(newVal.time_from || nowTz(), DATE_FORMAT),
          getTime(newVal.time_to || nowTz(), DATE_FORMAT),
          getTime(newVal.time_from, GET_TIME),
          getTime(newVal.time_to, GET_TIME),
          newVal.buffer_quota,
          // при создании квоты изначально отмечены все типы авто,
          // при редактировании - то, что заполнено в сохраненной квоте
          newVal.created_at ? newVal.truck_types : this.allTruckTypesOptions,
          newVal.suppliers_ids,
          newVal.id,
          newVal.unload_line_id,
        )
      },
    },
  },
  methods: {
    timeConvert,
    getQuotaTzDate,
    getTime,
    ...mapActions({
      createQuota: POST_SUPERVISOR_QUOTA,
      updateQuota: PUT_SUPERVISOR_QUOTA,
    }),
    setDateNow() {
      // если уже установлен временной интервал - передвигаем его,
      // иначе ставим время действия 1 час по дефолту
      let duration = 0

      if (this.ruleForm.time_to) {
        duration = diffSupervisorDates(
          this.ruleForm.date_from,
          this.ruleForm.time_from,
          this.ruleForm.date_to,
          this.ruleForm.time_to,
        )
      }
      this.ruleForm.date_from = getFormattedTzNow()
      this.ruleForm.time_from = getTzTime(nowTz(), GET_TIME)

      if (duration) {
        this.ruleForm.time_to = addIntervalTimeTz(
          this.ruleForm.date_from,
          this.ruleForm.time_from,
          duration,
        )
        this.ruleForm.date_to = addIntervalDateTz(
          this.ruleForm.date_from,
          this.ruleForm.time_from,
          duration,
        )
      } else {
        this.ruleForm.time_to = addHourTz(nowTz(), GET_TIME)
      }
    },
    handleSubmitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let data = this.setRequestData(this.ruleForm)
          let method = this.itemQuota.isEdit ? 'updateQuota' : 'createQuota'

          this[method](data)
          this.setDialog({ name: this.name, visible: false })
        }
      })
    },
    setRequestData(data) {
      return {
        unload_line_id: data.unload_line_id,
        time_from: this.getQuotaTzDate(data.date_from, data.time_from),
        time_to: this.getQuotaTzDate(data.date_to, data.time_to),
        suppliers_ids: data.supplier_id ? [data.supplier_id] : [],
        buffer_quota: data.count,
        truck_type_codes: data.truck_type_codes,
        culture_id: data.culture_id === 'all' ? null : data.culture_id,
        id: data.itemQuotaId,
      }
    },
    setFormFields(
      culture_id = 'all',
      date_from = null,
      date_to = null,
      time_from = '',
      time_to = '',
      count = 1,
      truck_type_codes = [],
      suppliers_ids = [],
      itemQuotaId,
      unload_line_id,
    ) {
      this.ruleForm.culture_id = culture_id === null ? 'all' : culture_id
      this.ruleForm.date_from = date_from
      this.ruleForm.date_to = date_to
      this.ruleForm.time_from = time_from || getTzTime(nowTz(), GET_TIME)
      this.ruleForm.time_to = time_to
      this.ruleForm.count = count
      this.ruleForm.truck_type_codes = truck_type_codes
      this.ruleForm.itemQuotaId = itemQuotaId
      this.ruleForm.unload_line_id = unload_line_id
      this.ruleForm.supplier_id = suppliers_ids[0] || null
      this.$refs.ruleForm?.clearValidate()
    },
    beforeClose() {
      this.setFormFields()
    },
    handleCheckDate() {
      this.ruleForm.date_to = ''
      this.ruleForm.time_from = ''
      this.handleSetTime()
    },
    handleCheckDateTo(event) {
      if (this.ruleForm.date_from === event) {
        this.handleSetTime()
      }
    },
    handleSetTime() {
      const { date_from, date_to, time_from, time_to, count } = this.ruleForm
      let diffTimeFromTo = ''

      if (date_from && date_to && time_from && time_to && count) {
        const totalTimeFrom = `${this.getTime(
          date_from,
          DATE_FORMAT,
        )} ${time_from}`
        const totalTimeTo = `${this.getTime(date_to, DATE_FORMAT)} ${time_to}`

        diffTimeFromTo = diffTime(totalTimeTo, totalTimeFrom, 'minutes')
      }
      diffTimeFromTo <= 0 ? (this.ruleForm.time_to = '') : null
    },
    clearValidate(field) {
      this.$refs.ruleForm.clearValidate(field)
    },
  },
}
</script>

<style lang="sass" scoped>
.editForm
  &__pickers
    display: flex
    justify-content: space-between
    align-items: center
    @media (max-width: 1199px)
      flex-direction: column
      align-items: flex-start

    &-block
      display: flex
      align-items: center
      gap: 10px
      width: 50%
      @media (max-width: 1199px)
        flex-direction: column
        align-items: flex-start
        gap: 8px
        width: 100%

      .datepicker, .timepicker
        margin-bottom: 0 !important
        @media (max-width: 1199px)
          width: 100%
          margin-bottom: 10px !important
        :deep(.el-input__inner)
          padding-right: 10px
      .datepicker__input
        width: 125px
        @media (max-width: 1199px)
          width: 100%
      .timepicker__input
        width: 85px
        @media (max-width: 1199px)
          width: 100%

  &__now
    margin: 15px 20px
    @media (max-width: 1199px)
      margin: -6px 0 10px
    &-button :deep(span)
      color: $btn-orange !important
      border-bottom: 1px solid $btn-orange !important

  &__select .el-select, &__input .el-input-number
    width: 100%

  .truck-types
    display: flex
    flex-direction: column

  &__submit
    display: flex
    justify-content: flex-end
    margin-top: 28px
    &-button
      width: 280px
      @media (max-width: 767px)
        width: 100%
</style>
